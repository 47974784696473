/**
 *
 * ConsoleBreadcrumbs
 *
 */

import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Typography, Link } from '@mamacrowd/ui-kit';
import { Paper, Breadcrumbs } from '@mui/material';

import FormattedMessage from 'components/FormattedMessage';
import sidebarMenuMessages from 'console/components/SidebarMenu/messages';
import {
  makeSelectLocation,
  makeSelectConsoleActiveCampaign,
  makeSelectConsoleActiveCompanyId,
  makeSelectConsoleActiveOrderId,
  makeSelectConsoleActiveUserId,
  makeSelectConsoleActiveMessageId,
  makeSelectConsoleActiveBannerId,
  makeSelectConsoleActiveArticleId,
  makeSelectConsoleActivePartnerId,
  makeSelectConsoleActiveTestimonialId,
  makeSelectConsoleActivePageId,
  makeSelectConsoleActivePressId,
  makeSelectConsoleActiveFaqId,
  makeSelectConsoleActiveSettingId,
} from 'containers/App/selectors';

import {
  CONSOLE_PREFIX,
  CONSOLE_LIST_CAMPAIGNS_ROUTE,
  CONSOLE_LIST_COMPANIES_ROUTE,
  CONSOLE_LIST_ORDERS_ROUTE,
  CONSOLE_LIST_USERS_ROUTE,
  CONSOLE_LIST_MESSAGES_ROUTE,
  CONSOLE_LIST_BANNERS_ROUTE,
  CONSOLE_LIST_ARTICLES_ROUTE,
  CONSOLE_LIST_PARTNERS_ROUTE,
  CONSOLE_LIST_TESTIMONIALS_ROUTE,
  CONSOLE_LIST_PAGES_ROUTE,
  CONSOLE_LIST_PRESS_ROUTE,
  CONSOLE_LIST_FAQ_ROUTE,
  CONSOLE_LIST_SETTINGS_ROUTE,
} from 'utils/constants';
import { CampaignParenthoodMessages } from 'utils/globalMessages';

import { Campaign } from 'types/Campaign';

import { useStyle } from './Breadcrumbs.style';
import consoleBreadcrumbsMessages from './messages';

const messages = {
  ...consoleBreadcrumbsMessages,
  ...sidebarMenuMessages,
  ...CampaignParenthoodMessages,
};

export interface IConsoleBreadcrumbsProps {
  activeCampaign: Campaign;
  companyId: string;
  orderId: string;
  userId: string;
  messageId: string;
  bannerId: string;
  articleId: string;
  partnerId: string;
  testimonialId: string;
  pageId: string;
  pressId: string;
  faqId: string;
  settingId: string;
}

function ConsoleBreadcrumbs({
  activeCampaign,
  companyId,
  orderId,
  userId,
  messageId,
  bannerId,
  articleId,
  partnerId,
  testimonialId,
  pageId,
  pressId,
  faqId,
  settingId,
}: IConsoleBreadcrumbsProps) {
  const classes = useStyle();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [breadcrumbObject, setBreadcrumbsObject] = useState<string[]>([]);

  const checkIfInRoute = route =>
    location.pathname.indexOf(`${CONSOLE_PREFIX}${route}`) > -1;

  useEffect(() => {
    const breadcrumb = location && location.pathname ? location.pathname.split('/').splice(2).filter(v => !!v) : []; // eslint-disable-line prettier/prettier
    setBreadcrumbsObject(breadcrumb);
  }, [
    location,
    activeCampaign,
    companyId,
    orderId,
    userId,
    messageId,
    bannerId,
    articleId,
    partnerId,
    testimonialId,
    pageId,
    pressId,
    faqId,
    settingId,
  ]);

  const convertId = item => {
    if (checkIfInRoute(CONSOLE_LIST_CAMPAIGNS_ROUTE)) {
      if (activeCampaign && activeCampaign.id === item) {
        const { isPrivate, isRestricted, isSPV, isHidden } = activeCampaign;
        const types: string[] = [];
        if (isPrivate) {
          types.push(intl.formatMessage(messages.private));
        }
        if (isHidden) {
          types.push(intl.formatMessage(messages.hidden));
        }
        if (isRestricted) {
          types.push(intl.formatMessage(messages.restricted));
        }
        if (isSPV) {
          types.push(intl.formatMessage(messages.double_funding));
        }
        if (types.length) {
          return `${activeCampaign.title} [${types.join(', ')}]`;
        }
        return activeCampaign.title;
      } else {
        return item;
      }
    }
    if (checkIfInRoute(CONSOLE_LIST_COMPANIES_ROUTE)) {
      return item === companyId ? companyId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_ORDERS_ROUTE)) {
      return item === orderId ? orderId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_USERS_ROUTE)) {
      return item === userId ? userId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_MESSAGES_ROUTE)) {
      return item === messageId ? messageId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_BANNERS_ROUTE)) {
      return item === bannerId ? bannerId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_ARTICLES_ROUTE)) {
      return item === articleId ? articleId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PARTNERS_ROUTE)) {
      return item === partnerId ? partnerId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_TESTIMONIALS_ROUTE)) {
      return item === testimonialId ? testimonialId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PAGES_ROUTE)) {
      return item === pageId ? pageId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_PRESS_ROUTE)) {
      return item === pressId ? pressId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_FAQ_ROUTE)) {
      return item === faqId ? faqId : item;
    }
    if (checkIfInRoute(CONSOLE_LIST_SETTINGS_ROUTE)) {
      return item === settingId ? settingId : item;
    }
    return item;
  };

  const handleClickSection = section => {
    if (section) {
      history.push(section);
    }
  };

  return (
    <Paper elevation={0}>
      <Breadcrumbs aria-label="breadcrumb" separator="&#62;">
        {breadcrumbObject.map(item => {
          if (item) {
            let name = item;
            if (name.split('-').length) {
              name = name
                .split('-')
                .reduce(
                  (a, b) => a + b[0].toUpperCase() + b.substring(1, b.length),
                );
            }
            let link = null;
            if (item !== breadcrumbObject[breadcrumbObject.length - 1]) {
              const index = location.pathname.indexOf(item);
              link = location.pathname.substring(0, index + item.length);
            }
            const children =
              item === breadcrumbObject[2] ? (
                convertId(item)
              ) : (
                <FormattedMessage messages={messages} messageId={name} />
              );
            return link ? (
              <Link key={item} to={link} className={classes.textSection}>
                {children}
              </Link>
            ) : (
              <Typography
                key={item}
                color={link ? 'primary' : 'textPrimary'}
                onClick={() => {
                  handleClickSection(link);
                }}
              >
                {children}
              </Typography>
            );
          }
          return null;
        })}
      </Breadcrumbs>
    </Paper>
  );
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  activeCampaign: makeSelectConsoleActiveCampaign(),
  companyId: makeSelectConsoleActiveCompanyId(),
  orderId: makeSelectConsoleActiveOrderId(),
  userId: makeSelectConsoleActiveUserId(),
  messageId: makeSelectConsoleActiveMessageId(),
  bannerId: makeSelectConsoleActiveBannerId(),
  articleId: makeSelectConsoleActiveArticleId(),
  partnerId: makeSelectConsoleActivePartnerId(),
  testimonialId: makeSelectConsoleActiveTestimonialId(),
  pageId: makeSelectConsoleActivePageId(),
  pressId: makeSelectConsoleActivePressId(),
  faqId: makeSelectConsoleActiveFaqId(),
  settingId: makeSelectConsoleActiveSettingId(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ConsoleBreadcrumbs);
