/*
 * GLOBAL Messages
 *
 * This contains all the global text for reusable utilities
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.global';

export const InvestorTypeMessages = defineMessages({
  natural_person: {
    id: `${scope}.InvestorTypeMessages.natural_person.message`,
    defaultMessage: 'Natural Person',
  },
  legal_entity: {
    id: `${scope}.InvestorTypeMessages.legal_entity.message`,
    defaultMessage: 'Legal Entity',
  },
  natural_person_short: {
    id: `${scope}.InvestorTypeMessages.natural_person_short.message`,
    defaultMessage: 'PF',
  },
  legal_entity_short: {
    id: `${scope}.InvestorTypeMessages.legal_entity_short.message`,
    defaultMessage: 'PG',
  },
});

export const UserClusterMessages = defineMessages({
  retail: {
    id: `${scope}.UserClusterMessages.retail.message`,
    defaultMessage: 'Retail',
  },
  accelerator: {
    id: `${scope}.UserClusterMessages.accelerator.message`,
    defaultMessage: 'Accelerator',
  },
  retail_vehicle: {
    id: `${scope}.UserClusterMessages.retail_vehicle.message`,
    defaultMessage: 'Retail vehicle',
  },
  family_office: {
    id: `${scope}.UserClusterMessages.family_office.message`,
    defaultMessage: 'Family office',
  },
  business_angel: {
    id: `${scope}.UserClusterMessages.business_angel.message`,
    defaultMessage: 'Business angel',
  },
  venture_capital: {
    id: `${scope}.UserClusterMessages.venture_capital.message`,
    defaultMessage: 'Venture capital',
  },
  professional_investor: {
    id: `${scope}.UserClusterMessages.professional_investor.message`,
    defaultMessage: 'Professional investor',
  },
  unknown: {
    id: `${scope}.UserClusterMessages.unknown.message`,
    defaultMessage: 'Unknown',
  },
  retail_short: {
    id: `${scope}.UserClusterMessages.retail_short.message`,
    defaultMessage: 'R',
  },
  accelerator_short: {
    id: `${scope}.UserClusterMessages.accelerator_short.message`,
    defaultMessage: 'A',
  },
  retail_vehicle_short: {
    id: `${scope}.UserClusterMessages.retail_vehicle_short.message`,
    defaultMessage: 'RV',
  },
  family_office_short: {
    id: `${scope}.UserClusterMessages.family_office_short.message`,
    defaultMessage: 'FO',
  },
  business_angel_short: {
    id: `${scope}.UserClusterMessages.business_angel_short.message`,
    defaultMessage: 'BA',
  },
  venture_capital_short: {
    id: `${scope}.UserClusterMessages.venture_capital_short.message`,
    defaultMessage: 'VC',
  },
  professional_investor_short: {
    id: `${scope}.UserClusterMessages.professional_investor_short.message`,
    defaultMessage: 'PI',
  },
  unknown_short: {
    id: `${scope}.UserClusterMessages.unknown_short.message`,
    defaultMessage: 'U',
  },
});

export const PaymentStatusMessages = defineMessages({
  waiting_for_payment: {
    id: `${scope}.PaymentStatusMessages.waiting_for_payment.message`,
    defaultMessage: 'To be paid',
  },
  to_be_validated: {
    id: `${scope}.PaymentStatusMessages.to_be_validated.message`,
    defaultMessage: 'To be validated',
  },
  to_be_confirmed: {
    id: `${scope}.PaymentStatusMessages.to_be_confirmed.message`,
    defaultMessage: 'To be confirmed',
  },
  to_cash_in: {
    id: `${scope}.PaymentStatusMessages.to_cash_in.message`,
    defaultMessage: 'Paid',
  },
  approved: {
    id: `${scope}.PaymentStatusMessages.approved.message`,
    defaultMessage: 'Cashed',
  },
  refunded: {
    id: `${scope}.PaymentStatusMessages.refunded.message`,
    defaultMessage: 'Refunded',
  },
  canceled: {
    id: `${scope}.PaymentStatusMessages.canceled.message`,
    defaultMessage: 'Canceled',
  },
  cancel_requested: {
    id: `${scope}.PaymentStatusMessages.cancel_requested.message`,
    defaultMessage: 'Cancel requested',
  },
  aborted: {
    id: `${scope}.PaymentStatusMessages.aborted.message`,
    defaultMessage: 'Aborted',
  },
});

export const ChamberDocValidationMessages = defineMessages({
  to_be_validated: {
    id: `${scope}.ChamberDocValidationMessages.to_be_validated.message`,
    defaultMessage: 'To be validated',
  },
  richdata_validated: {
    id: `${scope}.ChamberDocValidationMessages.richdata_validated.message`,
    defaultMessage: 'Richdata validated',
  },
  richdata_vat_validated: {
    id: `${scope}.ChamberDocValidationMessages.richdata_vat_validated.message`,
    defaultMessage: 'Richdata VAT validated',
  },
  richdata_vat_validated_lr_ok: {
    id: `${scope}.ChamberDocValidationMessages.richdata_vat_validated_lr_ok.message`,
    defaultMessage: 'Richdata VAT legal. rapr. validated',
  },
  richdata_mismatch: {
    id: `${scope}.ChamberDocValidationMessages.richdata_mismatch.message`,
    defaultMessage: 'Richdata mismatch',
  },
  mama_validated: {
    id: `${scope}.ChamberDocValidationMessages.mama_validated.message`,
    defaultMessage: 'Mama validated',
  },
  not_valid: {
    id: `${scope}.ChamberDocValidationMessages.not_valid.message`,
    defaultMessage: 'Not valid',
  },
});

export const SimStatusMessages = defineMessages({
  no: {
    id: `${scope}.SimStatusMessages.no.message`,
    defaultMessage: 'no',
  },
  requested: {
    id: `${scope}.SimStatusMessages.requested.message`,
    defaultMessage: 'Requested',
  },
  massive: {
    id: `${scope}.SimStatusMessages.massive.message`,
    defaultMessage: 'Massive',
  },
  massive_requested: {
    id: `${scope}.SimStatusMessages.massive_requested.message`,
    defaultMessage: 'Massive Requested',
  },
});

export const ReasonMessages = defineMessages({
  withdrawal: {
    id: `${scope}.ReasonMessages.withdrawal.message`,
    defaultMessage: 'Withdrawal',
  },
  revocation: {
    id: `${scope}.ReasonMessages.revocation.message`,
    defaultMessage: 'Revocation',
  },
  afterthought: {
    id: `${scope}.ReasonMessages.afterthought.message`,
    defaultMessage: 'Afterthought',
  },
  reflection: {
    id: `${scope}.ReasonMessages.reflection.message`,
    defaultMessage: 'Reflection',
  },
  mamacrowd: {
    id: `${scope}.ReasonMessages.mamacrowd.message`,
    defaultMessage: 'Mamacrowd',
  },
  mamacrowd_not_funded: {
    id: `${scope}.ReasonMessages.mamacrowd_not_funded.message`,
    defaultMessage: 'Mamacrowd not funded',
  },
  mamacrowd_over_funding: {
    id: `${scope}.ReasonMessages.mamacrowd_over_funding.message`,
    defaultMessage: 'Mamacrowd over funding',
  },
  mamacrowd_not_paid: {
    id: `${scope}.ReasonMessages.mamacrowd_not_paid.message`,
    defaultMessage: 'Mamacrowd not paid',
  },
  mamacrowd_anomaly: {
    id: `${scope}.ReasonMessages.mamacrowd_anomaly.message`,
    defaultMessage: 'Mamacrowd anomaly',
  },
  mamacrowd_junk: {
    id: `${scope}.ReasonMessages.mamacrowd_junk.message`,
    defaultMessage: 'Mamacrowd junk',
  },
  mamacrowd_not_approved_by_trust_company: {
    id: `${scope}.ReasonMessages.mamacrowd_not_approved_by_trust_company.message`,
    defaultMessage: 'Mamacrowd refused by Trust Company',
  },
  not_specified: {
    id: `${scope}.ReasonMessages.not_specified.message`,
    defaultMessage: 'Not Specified',
  },
});

export const FooterMessages = defineMessages({
  pec: {
    id: `${scope}.FooterMessages.pec.message`,
    defaultMessage:
      '© {currentYear} Mamacrowd Srl | Gruppo Azimut - P.IVA {vatNumber} - Via Timavo 34, 20124 MI - C.S. € {shareCapital} i.v. - Subs. n. 8, resolution n. 19002 in 06/08/2014',
  },
  pecLanding: {
    id: `${scope}.FooterMessages.pec_landing.message`,
    defaultMessage:
      '© {currentYear} Mamacrowd Srl | Gruppo Azimut - P.IVA {vatNumber} - Via Timavo 34, 20124 MI',
  },
  disclaimer: {
    id: `${scope}.FooterMessages.disclaimer.message`,
    defaultMessage:
      'Warnings pursuant to art. 19 para. 2{linebreak}the crowdfunding services provided by Mamacrowd do not fall under the deposit guarantee scheme established in accordance with Directive 2014/49/EU; the securities and instruments eligible for crowdfunding that can be acquired through this crowdfunding platform do not fall under the investor compensation scheme established in accordance with Directive 97/9/EC.',
  },
});

export const CampaignStatusMessages = defineMessages({
  onboarding: {
    id: `${scope}.CampaignStatusMessages.onboarding.message`,
    defaultMessage: 'Onboarding',
  },
  canceled: {
    id: `${scope}.CampaignStatusMessages.canceled.message`,
    defaultMessage: 'Canceled',
  },
  revoked: {
    id: `${scope}.CampaignStatusMessages.revoked.message`,
    defaultMessage: 'Revoked',
  },
  coming_soon: {
    id: `${scope}.CampaignStatusMessages.coming_soon.message`,
    defaultMessage: 'Coming Soon',
  },
  published: {
    id: `${scope}.CampaignStatusMessages.published.message`,
    defaultMessage: 'Published',
  },
  closing: {
    id: `${scope}.CampaignStatusMessages.closing.message`,
    defaultMessage: 'Closing',
  },
  closed_succesfully: {
    id: `${scope}.CampaignStatusMessages.closed_succesfully.message`,
    defaultMessage: 'Closed Succesfully',
  },
  closed_not_ok: {
    id: `${scope}.CampaignStatusMessages.closed_not_ok.message`,
    defaultMessage: 'Closed Not Ok',
  },
  exit: {
    id: `${scope}.CampaignStatusMessages.exit.message`,
    defaultMessage: 'Exit',
  },
});

export const CampaignTypeMessages = defineMessages({
  equity: {
    id: `${scope}.CampaignTypeMessages.equity.message`,
    defaultMessage: 'Startup/SMEs',
  },
  realEstate: {
    id: `${scope}.CampaignTypeMessages.realEstate.message`,
    defaultMessage: 'Real Estate',
  },
  realEstateIncome: {
    id: `${scope}.CampaignTypeMessages.realEstateIncome.message`,
    defaultMessage: 'Income Real Estate',
  },
  minibond: {
    id: `${scope}.CampaignTypeMessages.minibond.message`,
    defaultMessage: 'Minibond',
  },
  equity_fund: {
    id: `${scope}.CampaignTypeMessages.equity_fund.message`,
    defaultMessage: 'Equity Fund',
  },
});

export const CampaignDescriptionSectionMessages = defineMessages({
  coming_soon: {
    id: `${scope}.CampaignDescriptionSectionMessages.coming_soon.message`,
    defaultMessage: 'Progetto',
  },
  project: {
    id: `${scope}.CampaignDescriptionSectionMessages.project.message`,
    defaultMessage: 'Progetto',
  },
  product: {
    id: `${scope}.CampaignDescriptionSectionMessages.product.message`,
    defaultMessage: 'Prodotto',
  },
  customer_need: {
    id: `${scope}.CampaignDescriptionSectionMessages.customer_need.message`,
    defaultMessage: 'Bisogno del Cliente',
  },
  technology: {
    id: `${scope}.CampaignDescriptionSectionMessages.technology.message`,
    defaultMessage: 'Tecnologia',
  },
  target: {
    id: `${scope}.CampaignDescriptionSectionMessages.target.message`,
    defaultMessage: 'Mercato e Target',
  },
  traction: {
    id: `${scope}.CampaignDescriptionSectionMessages.traction.message`,
    defaultMessage: 'Customer e Traction',
  },
  marketing: {
    id: `${scope}.CampaignDescriptionSectionMessages.marketing.message`,
    defaultMessage: 'Marketing & Sales',
  },
  business_model: {
    id: `${scope}.CampaignDescriptionSectionMessages.business_model.message`,
    defaultMessage: 'Business Model',
  },
  competitors: {
    id: `${scope}.CampaignDescriptionSectionMessages.competitors.message`,
    defaultMessage: 'Competitors',
  },
  financial_forecast: {
    id: `${scope}.CampaignDescriptionSectionMessages.financial_forecast.message`,
    defaultMessage: 'Financial Projection',
  },
  money_usage: {
    id: `${scope}.CampaignDescriptionSectionMessages.money_usage.message`,
    defaultMessage: 'Use of Fund',
  },
  rewards: {
    id: `${scope}.CampaignDescriptionSectionMessages.rewards.message`,
    defaultMessage: 'Rewards',
  },
  location: {
    id: `${scope}.CampaignDescriptionSectionMessages.location.message`,
    defaultMessage: 'Location',
  },
  house_plan: {
    id: `${scope}.CampaignDescriptionSectionMessages.house_plan.message`,
    defaultMessage: 'House Plan',
  },
  abstract: {
    id: `${scope}.CampaignDescriptionSectionMessages.abstract.message`,
    defaultMessage: 'Abstract',
  },
  fiscal_benefit: {
    id: `${scope}.CampaignDescriptionSectionMessages.fiscal_benefit.message`,
    defaultMessage: 'Fiscal Benefit',
  },
  investment_strategy: {
    id: `${scope}.CampaignDescriptionSectionMessages.investment_strategy.message`,
    defaultMessage: 'Investment Strategy',
  },
  investment_statement: {
    id: `${scope}.CampaignDescriptionSectionMessages.investment_statement.message`,
    defaultMessage: 'Investment Statement',
  },
  why_invest: {
    id: `${scope}.CampaignDescriptionSectionMessages.why_invest.message`,
    defaultMessage: 'Why Invest',
  },
  bidder_and_promoter: {
    id: `${scope}.CampaignDescriptionSectionMessages.bidder_and_promoter.message`,
    defaultMessage: 'Bidder and Promoter',
  },
  financial_statement: {
    id: `${scope}.CampaignDescriptionSectionMessages.financial_statement.message`,
    defaultMessage: 'Financial Statement',
  },
  safeguards: {
    id: `${scope}.CampaignDescriptionSectionMessages.safeguards.message`,
    defaultMessage: 'Safeguards',
  },
  team: {
    id: `${scope}.CampaignDescriptionSectionMessages.team.message`,
    defaultMessage: 'Team',
  },
  risks: {
    id: `${scope}.CampaignDescriptionSectionMessages.risks.message`,
    defaultMessage: 'Risks',
  },
  exit: {
    id: `${scope}.CampaignDescriptionSectionMessages.exit.message`,
    defaultMessage: 'Exit',
  },
  placing_subject: {
    id: `${scope}.CampaignDescriptionSectionMessages.placing_subject.message`,
    defaultMessage: 'Placing Subject',
  },
});

export const CampaignWizardStepsMessages = defineMessages({
  stepCompany: {
    id: `${scope}.CampaignWizardStepsMessages.step_company.message`,
    defaultMessage: 'Company',
  },
  stepCampaign: {
    id: `${scope}.CampaignWizardStepsMessages.step_campaign.message`,
    defaultMessage: 'Campaign',
  },
  stepTeamMembers: {
    id: `${scope}.CampaignWizardStepsMessages.step_team_members.message`,
    defaultMessage: 'Team Members',
  },
  stepDescriptionSections: {
    id: `${scope}.CampaignWizardStepsMessages.step_description_sections.message`,
    defaultMessage: 'Description Sections',
  },
  stepFinancialMetrics: {
    id: `${scope}.CampaignWizardStepsMessages.step_financial_metrics.message`,
    defaultMessage: 'Financial Data',
  },
  stepQuotas: {
    id: `${scope}.CampaignWizardStepsMessages.step_quotas.message`,
    defaultMessage: 'Edit quotas',
  },
  stepRewards: {
    id: `${scope}.CampaignWizardStepsMessages.step_rewards.message`,
    defaultMessage: 'Edit rewards',
  },
  stepFinal: {
    id: `${scope}.CampaignWizardStepsMessages.step_final.message`,
    defaultMessage: 'Richiedi pubblicazione',
  },
});

export const BillingExtraTypeMessages = defineMessages({
  webinar: {
    id: `${scope}.BillingExtraTypeMessages.webinar.message`,
    defaultMessage: 'Webinar',
  },
  penalty: {
    id: `${scope}.BillingExtraTypeMessages.penalty.message`,
    defaultMessage: 'Penalty',
  },
  further_success_fee: {
    id: `${scope}.BillingExtraTypeMessages.further_success_fee.message`,
    defaultMessage: 'Further success fee',
  },
  discount: {
    id: `${scope}.BillingExtraTypeMessages.discount.message`,
    defaultMessage: 'Discount',
  },
  sim: {
    id: `${scope}.BillingExtraTypeMessages.sim.message`,
    defaultMessage: 'SIM',
  },
  trust_company: {
    id: `${scope}.BillingExtraTypeMessages.trust_company.message`,
    defaultMessage: 'Trust Company',
  },
  custom: {
    id: `${scope}.BillingExtraTypeMessages.custom.message`,
    defaultMessage: 'Custom',
  },
});

export const InvestorKnowledgeStatusMessages = defineMessages({
  appropriate: {
    id: `${scope}.InvestorKnowledgeStatusMessages.appropriate.message`,
    defaultMessage: 'Appropriate',
  },
  not_appropriate: {
    id: `${scope}.InvestorKnowledgeStatusMessages.not_appropriate.message`,
    defaultMessage: 'Not Appropriate',
  },
  to_be_verified: {
    id: `${scope}.InvestorKnowledgeStatusMessages.to_be_verified.message`,
    defaultMessage: 'To be verified',
  },
  unknown: {
    id: `${scope}.InvestorKnowledgeStatusMessages.unknown.message`,
    defaultMessage: 'Unknown',
  },
});

export const InvestorCategoryMessages = defineMessages({
  non_sophisticated: {
    id: `${scope}.InvestorCategoryMessages.non_sophisticated.message`,
    defaultMessage: 'Non sophisticated',
  },
  sophisticated: {
    id: `${scope}.InvestorCategoryMessages.sophisticated.message`,
    defaultMessage: 'Sophisticated',
  },
  professional: {
    id: `${scope}.InvestorCategoryMessages.professional.message`,
    defaultMessage: 'Professional',
  },
});

export const EarlyBirdMessages = defineMessages({
  startup_range: {
    id: `${scope}.EarlyBirdMessages.startup_range.message`,
    defaultMessage: 'Startup',
  },
  less_than_1k: {
    id: `${scope}.EarlyBirdMessages.less_than_1k.message`,
    defaultMessage: 'Meno di 1.000 €',
  },
  between_1_5k: {
    id: `${scope}.EarlyBirdMessages.between_1_5k.message`,
    defaultMessage: 'Tra 1.000 € e 5.000 €',
  },
  between_5_15k: {
    id: `${scope}.EarlyBirdMessages.between_5_15k.message`,
    defaultMessage: 'Tra 5.000 € e 15.000 €',
  },
  more_than_15k: {
    id: `${scope}.EarlyBirdMessages.more_than_15k.message`,
    defaultMessage: 'Più di 15.000 €',
  },
  sme_range: {
    id: `${scope}.EarlyBirdMessages.sme_range.message`,
    defaultMessage: 'SME',
  },
  less_than_2k: {
    id: `${scope}.EarlyBirdMessages.less_than_2k.message`,
    defaultMessage: 'Meno di 2.000 €',
  },
  between_2_10k: {
    id: `${scope}.EarlyBirdMessages.between_2_10k.message`,
    defaultMessage: 'Tra 2.000 € e 10.000 €',
  },
  between_10_30k: {
    id: `${scope}.EarlyBirdMessages.between_10_30k.message`,
    defaultMessage: 'Tra 10.000 € e 30.000 €',
  },
  more_than_30k: {
    id: `${scope}.EarlyBirdMessages.more_than_30k.message`,
    defaultMessage: 'Più di 30.000 €',
  },
  real_estate_range: {
    id: `${scope}.EarlyBirdMessages.real_estate_range.message`,
    defaultMessage: 'Real Estate',
  },
  less_than_10k: {
    id: `${scope}.EarlyBirdMessages.less_than_10k.message`,
    defaultMessage: 'Meno di 10.000 €',
  },
  between_10k_50k: {
    id: `${scope}.EarlyBirdMessages.between_10k_50k.message`,
    defaultMessage: 'Tra 10.000 € e 50.000 €',
  },
  between_50k_100k: {
    id: `${scope}.EarlyBirdMessages.between_50k_100k.message`,
    defaultMessage: 'Tra 50.000 € e 100.000 €',
  },
  more_than_100k: {
    id: `${scope}.EarlyBirdMessages.more_than_100k.message`,
    defaultMessage: 'Più di 100.000 €',
  },
});

export const RightsMessages = defineMessages({
  property: {
    id: `${scope}.RightsMessages.property.message`,
    defaultMessage: 'Property',
  },
  antiDilution: {
    id: `${scope}.RightsMessages.anti_dilution.message`,
    defaultMessage: 'Anti-Dilution',
  },
  coSale: {
    id: `${scope}.RightsMessages.co_sale.message`,
    defaultMessage: 'Co-Sale',
  },
  putOption: {
    id: `${scope}.RightsMessages.put_option.message`,
    defaultMessage: 'Put Option',
  },
  drag: {
    id: `${scope}.RightsMessages.drag.message`,
    defaultMessage: 'dragging',
  },
  liquidation: {
    id: `${scope}.RightsMessages.liquidation.message`,
    defaultMessage: 'Liquidation',
  },
  nomination: {
    id: `${scope}.RightsMessages.nomination.message`,
    defaultMessage: 'Nomination',
  },
  preemption: {
    id: `${scope}.RightsMessages.preemption.message`,
    defaultMessage: 'Preemption',
  },
  reservedTopic: {
    id: `${scope}.RightsMessages.reserved_topic.message`,
    defaultMessage: 'reserved topic',
  },
  voting: {
    id: `${scope}.RightsMessages.voting.message`,
    defaultMessage: 'voting',
  },
  votingLimited: {
    id: `${scope}.RightsMessages.voting_limited.message`,
    defaultMessage: 'Voting Limited',
  },
  information: {
    id: `${scope}.RightsMessages.information.message`,
    defaultMessage: 'Information',
  },
  propertyLimited: {
    id: `${scope}.RightsMessages.property_limited.message`,
    defaultMessage: 'Property Limited',
  },
  unlimitedTransfer: {
    id: `${scope}.RightsMessages.unlimited_transfer.message`,
    defaultMessage: 'Voting Limited',
  },
  dragPassive: {
    id: `${scope}.RightsMessages.drag_passive.message`,
    defaultMessage: 'Drag Passive',
  },
  votingSpecialMeetings: {
    id: `${scope}.RightsMessages.voting_special_meetings.message`,
    defaultMessage: 'Voting Special Meetings',
  },
});

export const RightsDescriptionMessages = defineMessages({
  property: {
    id: `${scope}.RightsDescriptionMessages.property.message`,
    defaultMessage:
      'di partecipare alla suddivisione degli utili della società in caso distribuzione dividendi o remunerazione pro-quota in caso di exit/vendita totalitaria della società.',
  },
  antiDilution: {
    id: `${scope}.RightsDescriptionMessages.anti_dilution.message`,
    defaultMessage: 'Right of Anti-Dilution',
  },
  coSale: {
    id: `${scope}.RightsDescriptionMessages.co_sale.message`,
    defaultMessage:
      "di vendere le proprie partecipazioni societarie alle medesime condizioni dei soci di controllo, qualora un terzo presenti un'offerta volta ad acquisire una partecipazione pari ad almeno il 50,01% del capitale sociale.",
  },
  putOption: {
    id: `${scope}.RightsDescriptionMessages.put_option.message`,
    defaultMessage:
      'di garantire al titolare il diritto di vendere un asset a un prezzo stabilito entro una scadenza prestabilita.',
  },
  drag: {
    id: `${scope}.RightsDescriptionMessages.drag.message`,
    defaultMessage:
      "di obbligare gli altri soci a partecipare alla cessione della società, qualora un terzo presenti un' offerta volta ad acquisire una partecipazione pari ad almeno il 50,01% del capitale sociale.",
  },
  liquidation: {
    id: `${scope}.RightsDescriptionMessages.liquidation.message`,
    defaultMessage:
      'di preferenza sui proventi da eventi di distribuzione (clausola di liquidation preference): gli investitori sono in ogni caso rimborsati per primi in caso di exit rispetto agli altri quotisti della società.',
  },
  nomination: {
    id: `${scope}.RightsDescriptionMessages.nomination.message`,
    defaultMessage:
      'di nominare uno o più membri del consiglio di amministrazione.',
  },
  preemption: {
    id: `${scope}.RightsDescriptionMessages.preemption.message`,
    defaultMessage:
      'di essere preferiti, a parità di condizioni, in caso di cessione della partecipazione sociale a terzi.',
  },
  reservedTopic: {
    id: `${scope}.RightsDescriptionMessages.reserved_topic.message`,
    defaultMessage: 'Right of reserved topic',
  },
  voting: {
    id: `${scope}.RightsDescriptionMessages.voting.message`,
    defaultMessage:
      'di partecipare alle assemblee societarie ordinarie e straordinarie della società, nonchè di esercitare diritto di voto determinante sulle materie riservate in assemblea.',
  },
  votingLimited: {
    id: `${scope}.RightsDescriptionMessages.voting_limited.message`,
    defaultMessage: 'Voting Limited',
  },
  information: {
    id: `${scope}.RightsDescriptionMessages.information.message`,
    defaultMessage: 'Information',
  },
  propertyLimited: {
    id: `${scope}.RightsDescriptionMessages.property_limited.message`,
    defaultMessage: 'Property Limited',
  },
  unlimitedTransfer: {
    id: `${scope}.RightsDescriptionMessages.unlimited_transfer.message`,
    defaultMessage: 'Voting Limited',
  },
  dragPassive: {
    id: `${scope}.RightsDescriptionMessages.drag_passive.message`,
    defaultMessage: 'Drag Passive',
  },
  votingSpecialMeetings: {
    id: `${scope}.RightsDescriptionMessages.voting_special_meetings.message`,
    defaultMessage: 'Voting Special Meetings',
  },
});

export const PortfolioTooltipMessages = defineMessages({
  orderTooltip: {
    id: `${scope}.PortfolioTooltipMessages.order_tooltip.message`,
    defaultMessage: 'orderTooltip',
  },
  cumulativeDateTooltip: {
    id: `${scope}.PortfolioTooltipMessages.cumulative_date_tooltip.message`,
    defaultMessage: 'cumulativeDateTooltip',
  },
  weightPercentageTooltip: {
    id: `${scope}.PortfolioTooltipMessages.weight_percentage_tooltip.message`,
    defaultMessage: 'weightPercentageTooltip',
  },
  postMoneyTooltip: {
    id: `${scope}.PortfolioTooltipMessages.post_money_tooltip.message`,
    defaultMessage: 'postMoneyTooltip',
  },
  annualROITooltip: {
    id: `${scope}.PortfolioTooltipMessages.annual_roi_tooltip.message`,
    defaultMessage: 'annualROITooltip',
  },
  valueTooltip: {
    id: `${scope}.PortfolioTooltipMessages.value_tooltip.message`,
    defaultMessage: 'valueTooltip',
  },
  refundDevelopTooltip: {
    id: `${scope}.PortfolioTooltipMessages.refund_develop_tooltip.message`,
    defaultMessage: 'refundDevelopTooltip',
  },
  refundIncomeTooltip: {
    id: `${scope}.PortfolioTooltipMessages.refund_income_tooltip.message`,
    defaultMessage: 'refundIncomeTooltip',
  },
  refundDateDevelopTooltip: {
    id: `${scope}.PortfolioTooltipMessages.refund_date_develop_tooltip.message`,
    defaultMessage: 'refundDateDevelopTooltip',
  },
  refundDateIncomeTooltip: {
    id: `${scope}.PortfolioTooltipMessages.refund_date_income_tooltip.message`,
    defaultMessage: 'refundDateIncomeTooltip',
  },
  finalRefundDevelopTooltip: {
    id: `${scope}.PortfolioTooltipMessages.final_refund_develop_tooltip.message`,
    defaultMessage: 'finalRefundDevelopTooltip',
  },
  finalRefundIncomeTooltip: {
    id: `${scope}.PortfolioTooltipMessages.final_refund_income_tooltip.message`,
    defaultMessage: 'finalRefundIncomeTooltip',
  },
  durationDevelopTooltip: {
    id: `${scope}.PortfolioTooltipMessages.duration_develop_tooltip.message`,
    defaultMessage: 'durationDevelopTooltip',
  },
  durationIncomeTooltip: {
    id: `${scope}.PortfolioTooltipMessages.duration_income_tooltip.message`,
    defaultMessage: 'durationIncomeTooltip',
  },
  totalROITooltip: {
    id: `${scope}.PortfolioTooltipMessages.total_roi_tooltip.message`,
    defaultMessage: 'totalROITooltip',
  },
  annualIRRTooltip: {
    id: `${scope}.PortfolioTooltipMessages.annual_irr_tooltip.message`,
    defaultMessage: 'annualIRRTooltip',
  },
  annualDividendTooltip: {
    id: `${scope}.PortfolioTooltipMessages.annual_dividend_tooltip.message`,
    defaultMessage: 'annualDividendTooltip',
  },
});

export const CRMEmailAudienceMessages = defineMessages({
  order_complete: {
    id: `${scope}.CRMEmailAudience.order_complete.message`,
    defaultMessage: 'Order Completed',
  },
  order_complete_helper: {
    id: `${scope}.CRMEmailAudience.order_complete_helper.message`,
    defaultMessage: 'Order completed helper',
  },
  order_start: {
    id: `${scope}.CRMEmailAudience.order_start.message`,
    defaultMessage: 'Order Start',
  },
  order_start_helper: {
    id: `${scope}.CRMEmailAudience.order_start_helper.message`,
    defaultMessage: 'Order Start helper',
  },
  early_bird: {
    id: `${scope}.CRMEmailAudience.early_bird.message`,
    defaultMessage: 'Early bird',
  },
  early_bird_helper: {
    id: `${scope}.CRMEmailAudience.early_bird_helper.message`,
    defaultMessage: 'Early bird helper',
  },
  follower: {
    id: `${scope}.CRMEmailAudience.follower.message`,
    defaultMessage: 'Follower',
  },
  follower_helper: {
    id: `${scope}.CRMEmailAudience.follower_helper.message`,
    defaultMessage: 'Follower helper',
  },
  document: {
    id: `${scope}.CRMEmailAudience.document.message`,
    defaultMessage: 'Document',
  },
  document_helper: {
    id: `${scope}.CRMEmailAudience.document_helper.message`,
    defaultMessage: 'document_helper',
  },
  view: {
    id: `${scope}.CRMEmailAudience.view.message`,
    defaultMessage: 'View',
  },
  view_helper: {
    id: `${scope}.CRMEmailAudience.view_helper.message`,
    defaultMessage: 'View Helper',
  },
  power_user: {
    id: `${scope}.CRMEmailAudience.power_user.message`,
    defaultMessage: 'Power User',
  },
  power_user_helper: {
    id: `${scope}.CRMEmailAudience.power_user_helper.message`,
    defaultMessage: 'Users with at least 1 investment in one year',
  },
  no_orders: {
    id: `${scope}.CRMEmailAudience.no_orders.message`,
    defaultMessage: 'Standard User',
  },
  no_orders_helper: {
    id: `${scope}.CRMEmailAudience.no_orders_helper.message`,
    defaultMessage: 'Users with no investments',
  },
  ex_power_user: {
    id: `${scope}.CRMEmailAudience.ex_power_user.message`,
    defaultMessage: 'EX Power User',
  },
  ex_power_user_helper: {
    id: `${scope}.CRMEmailAudience.ex_power_user_helper.message`,
    defaultMessage:
      'Users who have invested in the past but not in the last year',
  },
  interested: {
    id: `${scope}.CRMEmailAudience.interested.message`,
    defaultMessage: 'Interested',
  },
  interested_helper: {
    id: `${scope}.CRMEmailAudience.interested_helper.message`,
    defaultMessage: 'Interested Helper',
  },
});

export const CampaignParenthoodMessages = defineMessages({
  restricted: {
    id: `${scope}.CampaignParenthoodMessages.restricted.message`,
    defaultMessage: 'Reserved',
  },
  private: {
    id: `${scope}.CampaignParenthoodMessages.private.message`,
    defaultMessage: 'Private',
  },
  double_funding: {
    id: `${scope}.CampaignParenthoodMessages.double_funding.message`,
    defaultMessage: 'SPV',
  },
  hidden: {
    id: `${scope}.CampaignParenthoodMessages.hidden.message`,
    defaultMessage: 'Hidden',
  },
});
